import React from 'react';
import { saveCalendarData } from "./FirebaseUtil";
import { dateToStr } from "./Util";


export function RecordForm({ isRecordEditMode, closeEditMode, setCalendarData, uid, selectedDate, selectedData, setSelectedData}) {
    const initCalendarData = {
        records: [
          { title: "", body: "" }
        ],
        stamps: [],
        memo: "",
        exercise: 0,
        constipation: 0,
        health: 0
      };

    const dialogClose = (event) => {
        closeEditMode();
    };

    const handleStampSwitch = (event) => {
        event.currentTarget.dataset.switch = event.currentTarget.dataset.switch === "true" ? "false" : "true";
    }

    const handleSave = (event) => {
        event.preventDefault();
        event.stopPropagation();

        const records = Array.from(document.querySelectorAll('.record'))
            .map((recordEl, recordIndex) => {
                const title = recordEl.querySelector(`#title-${recordIndex}`).value;
                const body = recordEl.querySelector(`#body-${recordIndex}`).value;
                if (title === "" && body === "") {
                    return null; // title と body が空白の場合、null を返す
                }
                return { title, body };
            })
            .filter((record) => record !== null);

        const stamps = Array.from(document.querySelectorAll('.selectStamp'))
            .map((stampEl, stampIndex) => {
                const data = stampEl.dataset.switch;
                const icontype = stampEl.dataset.icontype;

                if (data === "false") {
                    return null; // title と body が空白の場合、null を返す
                } else {
                    return icontype;
                }
            })
            .filter((stamp) => stamp !== null);

        const memo = document.getElementById('textareaMemo').value;

        selectedData.stamps = stamps;
        selectedData.records = records;
        selectedData.memo = memo;

        saveCalendarData(uid, dateToStr(selectedDate), selectedData);
        setCalendarData(selectedData);

        closeEditMode();
    };

    const handleAddrecord = (event) => {
        setSelectedData(prevState => ({
            ...prevState,
            records: [...prevState.records, initCalendarData]
        }));
    };

    const handleDialogClick = (event) => {
        event.stopPropagation();
    };

    return (
        <div>
            {isRecordEditMode ? (
                <div className="dayDataForm">
                    <div className="dialogBase">
                        <div className="dialog" onClick={handleDialogClick}>
                            <button className="dialogClose" onClick={dialogClose}>×</button>

                            <div className="date">{selectedDate.getFullYear()}/{selectedDate.getMonth() + 1}/{selectedDate.getDate()}</div>

                            <form data-date={dateToStr(selectedDate)}>
                                <div className="records">
                                    <div className="card">
                                        <div className="label"><i className="las la-check"></i>RECORD of the day</div>
                                        {selectedData.records.map((record, recordIndex) => (
                                            <div className="record" key={recordIndex}>
                                                <label htmlFor={`title-${recordIndex}`} autocomplete="off">Title</label>
                                                <input type="text" id={`title-${recordIndex}`} defaultValue={record.title} placeholder="xxx" autocomplete="off"/>
                                                <label htmlFor={`body-${recordIndex}`}>Body</label>
                                                <textarea className="recordBody" id={`body-${recordIndex}`} defaultValue={record.body} placeholder="xxx"/>
                                            </div>
                                        ))}
                                        <button type="button" className="addBtn" onClick={handleAddrecord}>+</button>
                                    </div>

                                    <div className="card">
                                        <div className="label"><i className="las la-file-alt"></i> MEMO</div>
                                        <textarea id="textareaMemo" placeholder="xxx" defaultValue={selectedData.memo} />
                                    </div>

                                </div>

                                <div className="card">
                                    <div className="stamps">
                                        {selectedData.allStamps.map((stamp, index) => (
                                            <i className={`selectStamp las la-${stamp.type}`} data-icontype={`${stamp.type}`} data-switch={`${stamp.isOn}`} key={index} onClick={handleStampSwitch}></i>
                                        ))}
                                    </div>
                                </div>
                                <div className="btns">
                                    <button type="button" className="cancelBtn" onClick={dialogClose}>Cancel</button>
                                    <button type="button" className="saveBtn" onClick={handleSave}>Save</button>
                                </div>

                            </form>

                        </div>
                    </div>
                </div>
            ) : (
                <div></div>
            )}
        </div>
    );
}