import React, { useState, useEffect } from "react";
import { saveTodoData, loadTodoData } from "./FirebaseUtil";
import { dateToMonthStr } from "./Util";


export function TodoList({ isLoggedIn, uid, currentDate }) {

    const initTodoDatas = [{
        title: "",
        isDone: false,
        id: 0
    }];

    const [currentTodoData, setCurrentTodoData] = useState(initTodoDatas);
    const [isCanCopyTodo, setIsCanCopyTodo] = useState(false);

    // 配列がnullか空の場合はisCanCopyTodoをtrueにする関数
    const checkIsCanCopyTodo = (todoDatas) => {
        if (todoDatas === null || todoDatas.length === 0) {
            setIsCanCopyTodo(true);
        } else {
            setIsCanCopyTodo(false);
        }
    };

    useEffect(() => {
        if (isLoggedIn) {
            setIsCanCopyTodo(false);
            loadTodoData(uid, dateToMonthStr(currentDate))
                .then((data) => {
                    setCurrentTodoData(data);
                    if (data === null || data.length === 0) {
                        setIsCanCopyTodo(true);

                        const data = [{
                            title: "",
                            isDone: false,
                            id: currentDate.getFullYear() * 10000000 + (currentDate.getMonth() + 1) * 100000 + 1
                        }];
                        setCurrentTodoData(data);
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }, [currentDate, isLoggedIn]);

    // handleDeleteTodo
    // initTodoDatasからidを指定して削除する
    const handleDeleteTodo = (event) => {
        const id = parseInt(event.currentTarget.dataset.id, 10);

        const newTodoDatas = currentTodoData.filter((todo) => todo.id !== id);
        setCurrentTodoData(newTodoDatas);
        saveTodoData(uid, dateToMonthStr(currentDate), newTodoDatas);
        checkIsCanCopyTodo(newTodoDatas);

        console.log("handleDeleteTodo", dateToMonthStr(currentDate), newTodoDatas);
    };

    // handleAddTodo
    // initTodoDatasに新しいtodoを追加する
    const handleAddTodo = (event) => {
        let newTodoDatas = [];

        // idを新しく振る 2022010001
        const year = currentDate.getFullYear() * 10000000;
        const month = (currentDate.getMonth() + 1) * 100000;

        if (currentTodoData === null || currentTodoData.length === 0) {
            newTodoDatas = [{
                title: "",
                isDone: false,
                id: year + month + 1
            }];
        } else {
            const deletedIds = currentTodoData.filter(todo => !todo).map(todo => todo.id);
            const maxId = Math.max(...currentTodoData.map(todo => todo.id).concat(deletedIds));
            const last4DigitsId = maxId % 100000;
            const newId = last4DigitsId + 1 + year + month;

            newTodoDatas = currentTodoData.concat({
                title: "",
                isDone: false,
                id: newId
            });
        }
        setCurrentTodoData(newTodoDatas);
        saveTodoData(uid, dateToMonthStr(currentDate), newTodoDatas);
        checkIsCanCopyTodo(newTodoDatas);

        console.log("handleAddTodo", dateToMonthStr(currentDate), newTodoDatas);
    };

    // handleTodoTitleChange
    // initTodoDatasからidを指定してtitleを変更する
    const handleTodoTitleChange = (event) => {
        const id = parseInt(event.currentTarget.dataset.id);
        const newTodoDatas = currentTodoData.map((todo) => {
            if (todo.id === id) {
                todo.title = event.currentTarget.value;
            }
            return todo;
        });
        setCurrentTodoData(newTodoDatas);
        saveTodoData(uid, dateToMonthStr(currentDate), newTodoDatas);

        console.log("handleTodoTitleChange", dateToMonthStr(currentDate), newTodoDatas);
    };

    // handleTodoDoneChange
    // initTodoDatasからidを指定してisDoneを変更する
    const handleTodoDoneChange = (event) => {
        const id = parseInt(event.currentTarget.dataset.id);
        const newTodoDatas = currentTodoData.map((todo) => {
            if (todo.id === id) {
                todo.isDone = !todo.isDone;
                console.log(todo.isDone);
            }
            return todo;
        });
        setCurrentTodoData(newTodoDatas);
        saveTodoData(uid, dateToMonthStr(currentDate), newTodoDatas);

        console.log("handleTodoDoneChange", dateToMonthStr(currentDate), newTodoDatas);
    };

    // Copy last month's unfinished tasks to this month
    const handleCopyTodo = (event) => {
        const lastMonthDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
        loadTodoData(uid, dateToMonthStr(lastMonthDate))
            .then((data) => {
                if (data == null) {
                    return;
                }

                const newTodoDatas = currentTodoData.concat(data.filter((todo) => !todo.isDone).map((todo) => {
                    return {
                        title: todo.title,
                        isDone: false,
                        id: todo.id + 109000
                    };
                }));

                setCurrentTodoData(newTodoDatas);
                saveTodoData(uid, dateToMonthStr(currentDate), newTodoDatas);
                checkIsCanCopyTodo(newTodoDatas);

                console.log("handleCopyTodo", dateToMonthStr(currentDate), newTodoDatas);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    return (
        <div className="todoList" key={dateToMonthStr(currentDate)}>
            <label><i className="las la-crow"></i>WANT To Do</label>
            {currentTodoData ? (
                <ul>
                    {currentTodoData.map((todo, index) => (
                        <li key={"list_" + todo.id} className={todo.isDone ? "isDone" : ""}>
                            <i key={"icon_" + todo.id} className="lab la-font-awesome-flag" onClick={handleTodoDoneChange} data-id={todo.id}></i>
                            <input key={"title_" + todo.id} type="text" id={todo.id} defaultValue={todo.title} data-id={todo.id} placeholder="xxx" onChange={handleTodoTitleChange} autoComplete="off" />
                            <button key={"del_" + todo.id} type="button" className="delBtn" data-id={todo.id} onClick={handleDeleteTodo}>-</button>
                        </li>
                    ))}
                </ul>
            ) : (
                <div></div>
            )}

            <div className="btns">
                <button type="button" className="addBtn" onClick={handleAddTodo}>+</button>
                {isCanCopyTodo ? (
                    <button type="button" className="copyBtn" onClick={handleCopyTodo}>Copy last month's</button>
                ) : (
                    <div></div>
                )}
            </div>
        </div>
    );
}
