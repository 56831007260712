
export const dateToStr = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
};

export const dateToMonthStr = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    return `${year}-${month}`;
};


export const stampTypes = [
    "star",
    "grin-squint",
    "smile",
    "meh",
    "frown",
    "dizzy",
    "home",
    "shopping-basket",
    "car",
    "motorcycle",
    "running",
    "utensils",
    "beer",
    "book",
    "pen-fancy",
    "laptop",
    "tv",
    "gamepad",
    "camera-retro",

    "building",
    "hospital",
    "sun",
    "cloud",
    "umbrella",

    "piggy-bank",

    "poo",
    "female",
    "baby",
    "mask",

    "cat",
    "fish",
    "dragon"
]